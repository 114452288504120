// FullNodeModal.js
import React, { useState, useEffect } from "react";
import "./Modal.css"; // Import the CSS file
import ScheduleModal from "./ScheduleModal";

const FullNodeModal = ({ isOpen, onClose, dagNode, allDagNodes, diagramId, modalEditMode }) => {
  //TODO Add on close check on this variable
  const [isNodeEdited, setIsNodeEdited] = useState(false);
  const [scheduleTempValue, setScheduleTempValue] = useState(null);
  const [isScheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [selectedParentNodeId, setSelectedParentNodeId] = useState(null);
  const [dagNodeData, setDagNodeData] = useState({
    name: "",
    description: "",
    duedate: "",
    completed_at: "",
    notes: "",
    parentIds: [],
    scheduleValue: null,
    diagramId: diagramId,
    next_reset_at: "",
    last_reset_at: "",
  });

  const nameInUseMessage = "The name you entered for this node is already in use - the node has not been ";

  useEffect(() => {
    if (isOpen && allDagNodes?.length > 0) {
      setDagNodeData(allDagNodes[0]);
    }
  }, [isOpen]);

  useEffect(() => {
    if (dagNode !== null) {
      setDagNodeData(dagNode);
      setScheduleTempValue(dagNode.scheduleValue !== null ? dagNode.scheduleValue : null);
    } else {
      setDagNodeData({
        name: "",
        description: "",
        duedate: "",
        completed_at: "",
        notes: "",
        parentIds: [],
        scheduleValue: null,
        diagramId: diagramId,
      });
    }
  }, [dagNode]);


  if (!isOpen) return null;


  const openScheduleModal = () => {
    setScheduleModalOpen(true);
  };

  const closeScheduleModal = () => {
    setScheduleModalOpen(false);
};

  const setscheduleValue = (value) => {
    setScheduleTempValue(value);
    setIsNodeEdited(true);
  };

  const setScheduleModalInDagNode = (bool) => {
    dagNodeData["scheduleValue"] = bool ? scheduleTempValue : null;
  }

  const handleChange = (e) => {
    setDagNodeData({ ...dagNodeData, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (e) => {
    const selectedNodeName = e.target.value;
    const selectedNode = allDagNodes?.find(node => node.name === selectedNodeName);
    setDagNodeData(selectedNode);
  };
  
  const handleDeleteParent = (id) => {
    setDagNodeData(prevState => ({
      ...prevState,
      parentIds: prevState.parentIds.filter(parentId => parentId !== id)
    }));
  };

  function formatDateString(dateString) {
    var t = dateString.split(/[- :]/);
    // Apply each element to the Date function
    return t[0] + "-" + t[1] + "-" + t[2];
  }

  const handleDelete = (e) => {
    const csrfToken = document.querySelector("[name=csrf-token]").content;
    e.preventDefault();
    const confirmDelete = window.confirm("Are you sure you want to delete this node?");
    if (confirmDelete) {
    fetch("/dag_nodes", {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify(dagNodeData),
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }})
        .then((data) => {
            onClose();
        })
        .then(() => {
            window.location.reload();
        })
        .catch((error) => {
            console.error("Error:", error);
        });
    }
  };

  const handleSubmit = (e) => {
    const csrfToken = document.querySelector("[name=csrf-token]").content;
    e.preventDefault();
    setScheduleModalInDagNode(true);
    if ((dagNode === null || modalEditMode)) {
        fetch("/dag_nodes/", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify(dagNodeData),
        })
        .then((response) => {if (response.status === 409) {
          setScheduleModalInDagNode(false);
          alert(nameInUseMessage + "created");
        } else {
          window.location.reload();
        }})
        .catch((error) => {
            console.error("Error:", error);
        });
    } else {
        fetch("/dag_nodes", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify(dagNodeData),
        })
        .then((response) => {if (response.status === 409) {
          setScheduleModalInDagNode(false);
          alert(nameInUseMessage + "created");
        } else {
          window.location.reload();
        }})
        .catch((error) => {
            console.error("Error:", error);
        });
    }
  };

  function clearCompletedAtDatefield(elementName) {
    setDagNodeData({ ...dagNodeData, [elementName]: "" });
    document.getElementById(elementName).value = "";
    document.getElementById(elementName).setAttribute("value", "");
  }

  const scheduleTempValueAsString = () => {
    if (scheduleTempValue !== null && scheduleTempValue !== undefined && scheduleTempValue !== ""
        && scheduleTempValue["reset_schedule"] !== null && scheduleTempValue["reset_schedule"] !== undefined && scheduleTempValue["reset_schedule"] !== "") {
        return scheduleTempValue["reset_schedule"] + (scheduleTempValue["is_reoccurring"] ? " - Reoccurring" : " - One-off");
    }
    return "No Schedule Set";
  }

  return (
    <div className="overflow-y-auto flex h-screen flex-col flex-grow modal fixed inset-0 items-center justify-center z-50">
      <div style={{ backgroundColor: '#f1f0f5', height: '80%', width: '50%' }} className="flex flex-col h-screen overflow-hidden overflow-y-auto bg-white rounded-lg shadow-lg p-4">
        <header class="w-full text-center border-b border-grey p-4">
          <div className="flex justify-between items-center">
            <h1 className="modal-title text-2xl font-semibold text-gray-800">{(dagNode === null || modalEditMode) ? "Edit Node" : "Create Node"}</h1>
            <div>
                <i className="bi bi-x-lg cursor-pointer" onClick={onClose}></i>
            </div>
          </div>
        </header>
        <main class="flex-1 overflow-y-scroll pt-1">
          { (dagNode === null || modalEditMode) && 
              <>
              <label htmlFor="dagNode" className="mb-2 font-semibold text-gray-700">Node: </label>
              <select id="dagNodeSelector" name="dagNode" onChange={handleSelectChange} className="border border-gray-300 p-2 rounded">
                  {allDagNodes?.map((node, index) => (
                      <option key={index} value={node.name}>{node.name}</option>
                  ))}
              </select>
              </>
          }
          <form id="nodeModal" onSubmit={handleSubmit}>

            <div className="flex flex-col">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                value={dagNodeData.name}
                onChange={handleChange}
              />

              <label htmlFor="description">Description</label>
              <input
                type="text"
                name="description"
                value={dagNodeData.description}
                onChange={handleChange}
              />

              <label htmlFor="duedate">Due Date</label>
              <div className="flex justify-between">
                <input
                  type="date"
                  id="duedate"
                  name="duedate"
                  value={dagNodeData.duedate ? formatDateString(dagNodeData.duedate) : ""}
                  onChange={handleChange}
                  className="flex-grow mr-1" 
                />
                <button type="button" 
                        onClick={() => clearCompletedAtDatefield("duedate")} 
                        className="flex-shrink-0 ml-2 bg-zinc-500 hover:bg-zinc-700 text-white font-bold py-1 px-2 rounded object-right">
                          Clear
                </button>
              </div>

              <label htmlFor="completed_at">Completed At</label>
              <div className="flex justify-between">
                <input
                  type="date"
                  id="completed_at"
                  name="completed_at"
                  value={dagNodeData.completed_at ? formatDateString(dagNodeData.completed_at) : ""}
                  onChange={handleChange}
                  className="flex-grow mr-1" 
                />
                <button type="button" 
                        onClick={() => clearCompletedAtDatefield("completed_at")} 
                        className="flex-shrink-0 ml-2 bg-zinc-500 hover:bg-zinc-700 text-white font-bold py-1 px-2 rounded object-right">
                          Clear
                </button>
              </div>

              <label htmlFor="notes">Notes</label>
              <input
                type="text"
                name="notes"
                value={dagNodeData.notes}
                onChange={handleChange}
              />

              <label htmlFor="dagNodeParents" className="mb-2 font-semibold text-gray-700">Parent Links: </label>
              {dagNodeData.parentIds.map((id, index) => {
                const node = allDagNodes?.find(node => node.id === id);
                return (
                  <div key={index} className="flex items-center mb-2">
                    <span className="mr-2">{node.name}</span>
                    <button type="button" onClick={() => handleDeleteParent(id)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded">
                      X
                    </button>
                  </div>
                );
              })}

              <label htmlFor="addLinks" className="mb-2 font-semibold text-gray-700">Add parent links: </label>
              <div className="flex justify-between">
                  <select name="addLinks" 
                          onChange={e => {
                            var id = allDagNodes?.find(node => node.name === e.target.value).id; 
                            setSelectedParentNodeId(id); 
                            if(id !== null && id !== undefined && id !== "" && !dagNodeData.parentIds.includes(id)) {
                              setDagNodeData(prevState => ({
                                      ...prevState,
                                      parentIds: [...prevState.parentIds, id]
                                    }));
                              }
                      }} className="border border-gray-300 p-2 rounded mr-4">
                    <option key={0} value="NotSelected">Not Selected</option>
                    {allDagNodes?.filter((node) => node.id !== dagNodeData.id).map((node, index) => (
                        <option key={index+1} value={node.name}>{node.name}</option>
                    ))}
                </select>
              </div>
              <br></br>
              <label htmlFor="scheduleModal" className="mb-2 font-semibold text-gray-700">Completed At Reset Schedule: </label>
              <div className="flex justify-between">
                  <p name="scheduleValue" value={scheduleTempValue}>{scheduleTempValueAsString()}</p>
                  <button type="button" 
                          onClick={() => openScheduleModal()} 
                          className="bg-blue-500 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded object-right">
                            {/* Need to updated this logic so that when the scheduleTempValue[scheduleValue] is not empty it shows add Schedule */}
                            {(scheduleTempValue !== null && scheduleTempValue !== undefined && scheduleTempValue !== "" && scheduleTempValue["reset_schedule"] !== null) ? "Edit Schedule" : "Add Schedule"}
                  </button>
                  <ScheduleModal setscheduleValue={setscheduleValue} 
                                isScheduleModalOpen={isScheduleModalOpen} 
                                closeScheduleModal={closeScheduleModal}
                                existingSchedule={scheduleTempValue}>
                  </ScheduleModal>
              </div>
            </div>
            { (dagNodeData["scheduleValue"] !== null && dagNodeData["scheduleValue"] !== undefined && dagNodeData["scheduleValue"]["next_reset_at"] !== "" && dagNodeData["scheduleValue"]["next_reset_at"] !== null) && (
            <> 
              <br></br>
              <p name="nextResetAt" value={dagNodeData["next_reset_at"]}>Next Reset at: {dagNodeData["next_reset_at"]}</p>
            </>)
            }
            { (dagNodeData["scheduleValue"] !== null && dagNodeData["scheduleValue"] !== undefined && dagNodeData["scheduleValue"]["last_reset_at"] !== "" && dagNodeData["scheduleValue"]["last_reset_at"] !== null) && (
            <> 
              <br></br>
              <p name="lastResetAt" value={dagNodeData["last_reset_at"]}>Last Reset at: {dagNodeData["last_reset_at"]}</p>
            </>)
            }
            <br></br>
          </form>
        </main>
        <footer class="w-full text-center border-t border-grey p-2">
          <div className="flex justify-between">
            { (dagNode === null || modalEditMode) && 
                <>
                <button type="button" onClick={handleDelete} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                    Delete
                </button>
                </>
            }
            <button form="nodeModal" type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-auto">
                {(dagNode === null || modalEditMode) ? "Update" : "Create"}
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default FullNodeModal;
